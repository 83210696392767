
import {
  ChevronDownIcon,
  FilePlusIcon,
  ArrowRightIcon,
  LinkIcon,
  LoaderIcon,
} from "vue-feather-icons";
import ToastManager from "../../fc/ToastManager";
import Device from /* webpackChunkName: "UploadFromDevice" */ "./Upload/UploadFromDevice";
import {
  truncate,
  getProperName,
  getFileName,
  getExt,
  uuidv4,
  getUrlExtension,
  getExtFromFile,
} from "~/fc/helpers";
import { getType } from "~/fc/Dal/OptionsRepo";
import { initiateSocketConnection } from "~/fc/socket";
import FileValidationHandler from "~/fc/File/FileValidationHandler";
import MergeOptionHandler from "~/fc/AdvacedSettings/MergeOptionHandler";
import axios from "axios";
import FileListItemLoader from "./file/FileListItemLoader.vue";

export default {
  name: "FileInputDropDown",
  components: {
    ChevronDownIcon,
    FilePlusIcon,
    ArrowRightIcon,
    LinkIcon,
    LoaderIcon,
    Device,
    FileListItemLoader,
    FileList: () =>
      import(/* webpackChunkName: "FIleList" */ "../file-io/file/FIleList"),
    UrlInput: () =>
      import(/* webpackChunkName: "UploadFromUrl" */ "./Upload/UploadFromUrl"),
    Icon: () => import(/* webpackChunkName: "Icons" */ "../ui/icon/Icon"),
    TargetSelectorAll: () =>
      import(
        /* webpackChunkName: "TargetSelectorAll" */ "../file-io/TargetSelectorAll"
      ),
    Google: () => import(/* webpackChunkName: "Google" */ "./drivers/Google"),
    Dropbox: () =>
      import(/* webpackChunkName: "Dropbox" */ "./drivers/Dropbox"),
    Onedrive: () =>
      import(/* webpackChunkName: "Onedrive" */ "./drivers/Onedrive"),
    FullPageDrop: () =>
      import(/* webpackChunkName: "FullPageDrop" */ "../ui/FullPageDrop"),
    Modal: () => import(/* webpackChunkName: "Modal" */ "../modal"),
    Prompt: () => import(/* webpackChunkName: "Prompt" */ "../ui/Prompt"),
    BannerContainer: () =>
      import(
        /* webpackChunkName: "Banner" */ "~/components/ui/BannerContainer.vue"
      ),
    SmallFileSelectionFeedbackPrompt: () =>
      import(
        /* webpackChunkName: "Prompt" */ "../ui/SmallFileSelectionFeedbackPrompt"
      ),
    AutoDownloadCheckbox: () =>
      import(/* webpackChunkName: "Prompt" */ "../ui/AutoDownloadCheckbox"),
    MergeCheckbox: () =>
      import(
        /* webpackChunkName: "MergeCheckbox" */ "~/components/ui/MergeCheckbox"
      ),
    JobBuilderDialog: () =>
      import(
        /* webpackChunkName: "JobBuilderDialog" */ "~/components/file-io/JobBuilderDialog"
      ),
    IconCode: () =>
      import(
        /* webpackChunkName: "IconCode" */ "~/components/ui/icon/IconCode"
      ),
  },
  props: {
    single: {
      default: true,
      type: Boolean,
    },
    preview: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    show: false,
    errors: [],
    selectedTarget: "",
    shakeBtn: false,
    gDriveLoaded: false,
    dropboxLoaded: false,
    ondeDriveLoaded: false,
    conversionStarting: false,
    refreshMergeCheck: 0,
    loadingUploadInitiation: false,
    showSmallFileFeedbackDialog: false,
    domLoaded: false,
  }),
  computed: {
    isValidDimension() {
      return this.$store.state.isValidDimension;
    },
    isMobile() {
      return this.$store.state.store.isMobile;
    },
    showAutoSavetoDriveOption() {
      return this.$store.state.store.showAutoSavetoDriveOption;
    },
    allowDrop() {
      return true;
    },
    info() {
      return this.$store.state.info;
    },
    enablePreview() {
      return (
        this.info.active === "PreviewTemplate" ||
        (this.info.customVariables
          ? this.info.customVariables.enablePreview
          : false)
      );
    },
    showUploadMore() {
      return this.hasFiles && !this.enablePreview;
    },
    fileInputConcurrentLength() {
      return this.$store.state.fileInputConcurrentLength;
    },
    advancedSetting() {
      return this.$store.state.advancedSetting;
    },
    files() {
      return this.$store.state.list;
    },
    filesLoading () {
        return new Number(this.fileInputConcurrentLength) > Object.keys(this.files).length
    },
    fileAddInProgress() {
      return this.files.length < this.fileInputConcurrentLength;
    },
    showTargetSelectorAll() {
      return true;
    },
    hasFiles() {
      return !!this.$store.state.list.length;
    },
    toBeUploadedEvent() {
      return this.$store.state.toBeUploadedEvent;
    },
    services() {
      return [
        {
          name: this.$t("from_device"),
          icon: "folder",
          key: "device",
        },
        {
          name: this.$t("from_dropbox"),
          icon: "dropbox",
          key: "dropbox",
        },
        {
          name: this.$t("from_google_drive"),
          icon: "google_drive",
          key: "google",
        },
        {
          name: this.$t("from_onedrive"),
          icon: "onedrive",
          key: "onedrive",
        },
        {
          name: this.$t("from_url"),
          icon: "link",
          key: "url",
        },
      ];
    },
    allowedList() {
      const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      if (this.info) {
        if (
          this.info.customVariables &&
          this.info.customVariables.allowedExts
        ) {
          return this.info.customVariables.allowedExts;
        } else if (this.info.limitAcceptedFiles && this.info.allowedArr) {
          return this.info.allowedArr.filter(onlyUnique).join(",");
        }
      }
      return "";
    },
    hasUnselectedFile() {
      if (this.info.toolPage || this.enablePreview) {
        return false;
      }
      const { targetForFile, targetForExt } = this.advancedSetting;

      for (const item of this.files) {
        if (
          !targetForFile[item.id] ||
          !targetForFile[item.id].ready ||
          (targetForFile[item.id] && targetForFile[item.id].ext === "") ||
          !targetForExt[item.ext] ||
          !Object.keys(targetForExt[item.ext]).length
        ) {
          // we don't need to iterate anymore
          return true;
        }
      }

      return false;
    },
    convertButtonTitle() {
      if (this.uploadLoading) return this.$t("wait_until_all_files_loaded");
      if (this.mergeLimitCrossed) {
        return this.$t("merge_limit_reached_tooltip")
          .replace("NAME", $nuxt.$store.state.subscription.name || "Guest")
          .replace("LIMIT", $nuxt.$store.state.subscription?.maxMergeLimit);
      }
      return this.hasUnselectedFile
        ? this.$t("target_warning_tooltip")
        : this.files.length
        ? this.$store.state.info.toolPage
          ? false
          : this.$t("convert_now_tooltip")
        : this.$t("select_files_to_convert");
    },
    toastManager() {
      return new ToastManager(this);
    },
    acceptHtml() {
      return this.info.acceptHtml;
    },
    sizeLimit() {
      const UploadMaxLimit =
        this.$store.state.subscription.plan.maxFileSizeInGb + "GB";

      if (UploadMaxLimit.includes("GB")) {
        return (
          parseFloat(UploadMaxLimit.replace("GB", "")) * 1024 * 1024 * 1024
        );
      } else {
        return parseFloat(UploadMaxLimit.replace("MB", "")) * 1024 * 1024;
      }
    },
    allowedListFormatted() {
      return this.allowedList ? this.allowedList.split(",").join(", ") : "";
    },
    UploadMaxLimit() {
      return this.$store.state.subscription.plan.maxFileSizeInGb + "GB";
    },
    buttonText() {
      return this.info.button_text ? this.info.button_text : this.$t("convert");
    },
    userData() {
      return this.$store.state.advancedSetting.userData;
    },
    showMergingOption() {
      return this.files.length > 1
        ? MergeOptionHandler.showMergingOption()
        : false;
    },
    allowFileMerge() {
      return {
        check: this.refreshMergeCheck,
        allow: new MergeOptionHandler().allowMerge(),
      };
    },
    formattedTargetName() {
      return this.target.includes(" ")
        ? this.target
        : this.target.toUpperCase();
    },
    target() {
      if (this.files.length) {
        const { targetForFile } = this.$store.state.advancedSetting;
        let target = "";
        this.files.forEach(({ id }, i) => {
          if (!targetForFile[id] || !targetForFile[id].slug) {
            target = "";
          } else if (!i) {
            target = targetForFile[id];
          } else if (target && target.slug !== targetForFile[id].slug) {
            target = "";
          }
        });

        if (target) {
          if (target.device) {
            return `${target.name} ${target.type}`;
          }

          return target.slug;
        }

        // target is missing
        return this.selectedTarget;
      }

      // no files selected yet
      return this.selectedTarget;
    },
    merging() {
      return this.$store.state.advancedSetting.merging;
    },
    isWebpageConverter() {
      return this.$route.params.id
        ? this.$route.params.id.includes("webpage-to")
        : false;
    },
    filePickerText() {
      return this.isWebpageConverter
        ? this.$t("uploader_text_enter_webpage")
        : this.$t("uploader_text_choose_files");
    },
    filePickerMoreText() {
      return this.info.active && this.info.active === "PreviewTemplate"
        ? this.$t("uploader_text_choose_new_file")
        : this.isWebpageConverter
        ? this.$t("add_more_webpages")
        : this.$t("uploader_text_add_more_files");
    },
    filePickerIcon() {
      return this.isWebpageConverter ? "LinkIcon" : "FilePlusIcon";
    },
    uploadLoading() {
      const unsetFile = this.files.find(
        (file) =>
          !file.targetInitialized || file.targetInitialized === undefined
      );
      return (
        this.files.length != this.fileInputConcurrentLength ||
        (this.enablePreview ? false : unsetFile && unsetFile !== undefined)
      );
    },
    maxSelectFileLimit() {
      return process.env.MAX_SELECT_FILES ? process.env.MAX_SELECT_FILES : 500;
    },
    defaultTarget() {
      return this.$store.state.advancedSetting.defaultTarget;
    },
    fileValidationHandler() {
      return new FileValidationHandler(this, this.allowedList);
    },
    mergeLimitCrossed() {
      const maxMergeLimit = $nuxt.$store.state.subscription?.maxMergeLimit;
      return this.merging && this.files.length > maxMergeLimit;
    },
    allowJobBuilderLauncher() {
      let invalidUpload = this.files.find((file) => {
        return (
          file.gtoken ||
          file.uploadMethod === "dropbox" ||
          file.uploadMethod === "one_drive"
        );
      });
      return !invalidUpload;
    },
  },
  watch: {
    show(val) {
      if (val && !this.gDriveLoaded) {
        this.$refs.google.loadScript();
      }

      if (val && !this.dropboxLoaded) {
        this.$refs.dropbox.loadScript();
      }

      if (val && !this.ondeDriveLoaded) {
        this.$refs.onedrive.loadScript();
      }
    },
    hasFiles(val, oldVal) {
      if (val && !oldVal) {
        this.$gtm.push({ event: "file_selected" });
      }
      const breadcrumb = document.querySelector(".breadcrumb");

      if (breadcrumb) {
        this.enablePreview && val
          ? breadcrumb.classList.add("breadcrumb-toleft")
          : breadcrumb.classList.remove("breadcrumb-toleft");
      }
    },
    files: {
      handler: function (val, oldVal) {
        if (val) {
          this.closeFilePickerMenu();
          this.fileValidationHandler.validateMergePrivilege();
        }
      },
      deep: true,
    },
    uploadLoading: {
      handler: function (val, oldVal) {
        setTimeout(() => {
          this.refreshMergeCheck++;
        }, 1000);
      },
      deep: true,
      immediate: false,
    },
    showMergingOption: {
      handler: function (val, oldVal) {
        if (val === false && !this.enablePreview) {
          new MergeOptionHandler().disableMerge();
        }
      },
      immediate: false,
    },
    toBeUploadedEvent(evt) {
      this.addFiles(evt);
    },
  },
  created() {
    if (this.info.target && this.info.target.slug) {
      this.selectedTarget = this.info.target.slug;
    } else if (
      typeof this.info.target === "string" ||
      this.info.target instanceof String
    ) {
      this.selectedTarget = this.info.target;
    }
  },
  async mounted() {
    this.$emit("loaded");
    this.domLoaded = true;
    if (document.getElementById("FileInputDropDownTrigger")) {
      document.getElementById("FileInputDropDownTrigger").addEventListener(
        "touchstart",
        () => {
          this.show = !this.show;
        },
        { capture: false, passive: true }
      );
    }
    this.$bus.$on("goToDownloadPage", this.goToDownloadPage);
    this.$bus.$on("SELECT_FILE_VIA", this.showFilePicker);
    await this.checkIfFromGoogleDrive();
  },
  methods: {
    async checkIfFromGoogleDrive() {
      const { code, state, scope } = this.$route.query || {};
      this.loadingUploadInitiation = true;
      if (state && code && scope.includes("drive.file")) {
        this.toastManager.add(
          "success",
          "",
          this.$t("uploading_from_google_drive")
        );

        const stateVal = JSON.parse(state);
        window.history.replaceState({}, document.title, "/");
        this.$store.commit("store/setShowAutoSavetoDriveOption", true);
        try {
          const res = await this.$axios.post(
            process.env.ACCOUNT_SERVICE + "/auth/google-token",
            { code }
          );
          const token = res.data;
          this.$store.commit(
            "store/setGoogleDriveTokenForUpload",
            JSON.stringify(token)
          );
          if (!token) return;
          const promises = [];
          for (let id of stateVal.ids) {
            promises.push(
              new Promise(async (resolve) => {
                const fileInfoResult = await axios.get(
                  `https://www.googleapis.com/drive/v3/files/${id}?fields=*`,
                  { headers: { Authorization: `Bearer ${token.access_token}` } }
                );
                const remoteFile = fileInfoResult.data;
                const file = {
                  name: remoteFile.originalFilename,
                  gtoken: JSON.stringify(token),
                  url: remoteFile.id,
                  size: remoteFile.size,
                  folder: remoteFile.parents ? remoteFile.parents[0] : null,
                };
                this.addRemoteFile(file, "google");
                resolve();
              })
            );
          }
          await Promise.all(promises);
        } catch (error) {
          this.$store.commit("store/setShowAutoSavetoDriveOption", false);
          this.$store.commit("setAutoSaveToDrive", false);
        }
      } else {
        this.$store.commit("store/setShowAutoSavetoDriveOption", false);
        this.$store.commit("setAutoSaveToDrive", false);
      }
      this.loadingUploadInitiation = false;
    },
    shouldDisable(serviceKey) {
      if (serviceKey === "google" && !this.gDriveLoaded) {
        return true;
      } else if (serviceKey === "dropbox" && !this.dropboxLoaded) {
        return true;
      }

      return false;
    },
    getSourceExt() {
      if (this.info.sourceExt) return this.info.sourceExt;
      if (this.info.source && this.info.source.ext) return this.info.source.ext;
      if (this.info.type) return this.info.type;
      return this.info.target && this.info.target.group
        ? this.info.target.group.toLowerCase()
        : "";
    },
    showFilePicker(service) {
      const SERVICES = {
        dropbox: "dropbox",
        google: "google",
        onedrive: "onedrive",
        url: "urlUploader",
      };

      if (this.$refs[SERVICES[service]]) {
        this.$gtm.push({
          event: "file_upload_source",
          page: `${this.info.slug}-upload`,
          upload_source: service,
        });
        this.$refs[SERVICES[service]].open();
      }
      this.show = false;
    },
    addRemoteFiles(files, service) {
      if (this.fileInputConcurrentLength + files.length > this.maxSelectFileLimit) {
        this.showMaxFileSelectLimitError()
        return;
      }
      files.map((file) => {
        return this.addRemoteFile(file, service);
      });
    },
    addRemoteFile(file, service) {
      this.errors = [];
      const name = file.name;
      const ext = getExt(file.name);
      const baseName = name.replace("." + ext, "");
      this.$store.commit(
        "setFileInputConcurrentLength",
        this.fileInputConcurrentLength + 1
      );
      let accessToken = "";
      if (file.gtoken) {
        accessToken = JSON.parse(file.gtoken)?.access_token;
      }
      this.addItem({
        id: uuidv4(),
        file: "url",
        uploadMethod: file.uploadMethod,
        gtoken: file.gtoken,
        name,
        baseName,
        ext,
        url: file.url,
        done: file.size,
        total: file.size,
        folder: file.folder,
        gDriveParentFolderId: file.gDriveParentFolderId,
        src:
          service === "google"
            ? `https://lh3.googleusercontent.com/d/${file.url}?access_token=${accessToken}`
            : file.url,
      });
    },
    showMaxFileSelectLimitError () {
      this.toastManager.add('danger', '', this.$t("max_file_exceed_toast").replace('LIMIT', this.maxSelectFileLimit));
    },
    async addUrls(urls) {
      if ( this.fileInputConcurrentLength + urls.length > this.maxSelectFileLimit ) {
        this.showMaxFileSelectLimitError()
        return;
      }
      for (const url of urls) {
        this.$store.commit('setFileInputConcurrentLength', this.fileInputConcurrentLength + 1);
        await this.addItem({ id: uuidv4(), file: "url", ...url });
      }
      this.$refs.urlUploader.close();
    },
    isWebpageExt(ext) {
      return ext ? ["", "htm", "html", "webpage"].includes(ext) : true
    },
    addFiles(e) {
      this.errors = [];
      this.dragging = false;
      this.loadingUploadInitiation = true;
      const droppedFiles = Array.isArray(e)
        ? e
        : e.dataTransfer
        ? e.dataTransfer.files
        : e.target.files || [];

      if (!droppedFiles || !droppedFiles.length) {
        return;
      }

      if (this.fileInputConcurrentLength + droppedFiles.length > this.maxSelectFileLimit) {
        this.showMaxFileSelectLimitError()
        this.loadingUploadInitiation = false;
        return;
      }
      this.$store.commit('setFileInputConcurrentLength', this.single ? 1 : this.fileInputConcurrentLength + droppedFiles.length);
      if (this.single) {
        this.addFile(droppedFiles[0]);
      } else {
        for (var i = 0; i < droppedFiles.length; i++) {
          this.addFile(droppedFiles[i]);
        }
      }
    },
    async addFile(file) {
      if (!file) return;
      try {
        let name = getProperName(file.name);
        let ext =
          this.acceptHtml && this.isWebpageExt(getExt(name))
            ? "webpage"
            : getExt(name);
        const fileExt = await getExtFromFile(file);
        if (!ext || (fileExt && ext !== fileExt)) {
          ext = fileExt;
          name = name + "." + ext;
        }
        const baseName = name.replace("." + ext, "");
        this.addItem({
          id: uuidv4(),
          file,
          name,
          baseName,
          ext,
          url: null,
          total: file.size,
          done: 0,
        });
      } catch (error) {
        console.trace(error);
      }
    },
    /**
     * We are handling all the different file vaidations in the
     * FileValidationHandler class. If there are any validation
     * errors, we simply throw a specific custom error and catch
     * it on this try-catch block and handle the error.
     * */
    async addItem(item) {
      try {
        this.fileValidationHandler.setFile(item).validate();
        this.$store.dispatch("banner/setShowFreeLimitPerFileWarn", item);
        const byteSize = (str) => new Blob([str]).size;
        if (byteSize(item.name) > 255) {
          item.name = truncate(item.name, 50);
        }
        item = {
          ...item,
          added: Date.now(),
          operation: this.info.toolPage ? this.info.operation : "convert",
          targetInitialized: false,
          multiType: this.info.multiType,
          status: 1,
        };
        item.ext = item.ext.toLowerCase();
        this.single ? this.setList([item]) : this.add(item);
        this.$nuxt.$loading.finish();
        if (this.single) this.$store.commit("setFileInputConcurrentLength", 1);
        this.loadingUploadInitiation = false;
        if (item.total > 0 && item.total <= 100 && item.ext === "mp4") {
          this.showSmallFileFeedbackDialog = { ...item };
        }
      } catch (error) {
        this.$nuxt.$loading.finish();
        this.loadingUploadInitiation = false;
        this.$store.commit('setFileInputConcurrentLength', this.fileInputConcurrentLength - 1);
        this.fileValidationHandler.handle(error);
      }
    },
    async prepareDownloadPage() {
      await this.$store.commit("setDownloadPage", {
        target: this.target,
        targetName: this.targetName,
        convertMoreText: this.convertMoreText,
        pageName: document.querySelector(".convert-txt h1")
          ? document.querySelector(".convert-txt h1").innerText
          : document.title,
        resultPageH1: this.resultPageH1,
      });
    },
    async goToDownloadPage() {
      if (
        this.uploadLoading ||
        this.mergeLimitCrossed ||
        !this.isValidDimension
      )
        return false;
      if (this.hasUnselectedFile) {
        this.toastManager.add(
          "danger",
          "Error",
          this.$t("all_files_must_have_target")
        );
        this.shakeConvertButton();
        return;
      }

      initiateSocketConnection();
      this.$emit("goingToDownloadPage");
      await this.prepareDownloadPage();
      this.conversionStarting = true;
      this.redirectToDownloadPage();
    },
    redirectToDownloadPage() {
      const routeQuery = this.$route.fullPath.split("?")[1];
      if (routeQuery && routeQuery.includes("utm")) {
        this.$router.push(
          `${this.$route.path.replace(/\/$/, "")}/download${
            routeQuery ? "?" + routeQuery : ""
          }`
        );
      } else {
        this.$router.push(this.$route.path.replace(/\/$/, "") + "/download");
      }
    },
    hideModal() {
      this.$store.commit("hideError", "showInvalidPopup");
    },
    submitSmallFileUserFeedback(feedback) {
      if (feedback.userFeedback && feedback.userFeedback.length) {
        const eventId = this.$sentry.captureMessage("User Feedback");

        // event_id, name, email, comments is required.
        const userFeedback = {
          event_id: eventId,
          name: $nuxt.$auth?.user ? "Registered User" : "Guest User",
          email: feedback.userEmail,
          comments: feedback.userFeedback,
        };

        try {
          this.$sentry.captureUserFeedback(userFeedback);
          this.toastManager.add(
            "success",
            "",
            this.$t("feedback_submit_successfully")
          );
        } catch (error) {
          this.toastManager.add(
            "danger",
            "",
            this.$t("feedback_failed_submit")
          );
        }
      }
    },
    cancelSmallFileUserFeedbackModal() {
      this.showSmallFileFeedbackDialog = false;
    },
    add(item) {
      this.$store.commit("add", item);
    },
    setList(item) {
      this.$store.commit("setList", item);
    },
    shakeConvertButton() {
      this.shakeBtn = true;
      setTimeout(() => {
        this.shakeBtn = false;
      }, 500);
    },
    filePickerButtonAction() {
      return this.isWebpageConverter
        ? this.showFilePicker("url")
        : this.showFilePicker("device");
    },
    openFilePickerMenu() {
      document.getElementById("FileInputDropdownHolder").classList.add("hover");
    },
    closeFilePickerMenu() {
      document
        .getElementById("FileInputDropdownHolder")
        .classList.remove("hover");
    },
    handlePreLoadedFile(event) {
      const self = this;
      const loaded = setInterval(() => {
        if (self.domLoaded) {
          self.addFiles(event);
          clearInterval(loaded);
        }
      }, 100);
    },
    openAPIPageDialog() {
      if (!this.allowJobBuilderLauncher) return;
      this.$refs.JobBuilderDialog.open();
    },
  },
};
